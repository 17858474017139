<template>
	<div class="ucPanel pageTname">生产经营合同统计</div>
	<div class="ucPanel bgGrayBox ctms_srh ucBtn-bg" :class="{'ctms_srhHide' : shouldshow }">  <!-- 收起 添加 ctms_srhHide -->
		<div class="ctms_srhR_arr" @click="putaway">{{putawayname}}</div>
		<div class="ucForm ucForm-flow">
		    <div class="ucFrom-row">
				<div class="ucForm-group">
					<label class="ucForm-label">签订时间</label>
					<el-config-provider :locale="locale">
					<el-date-picker v-model="beginDate" type="date" value-format="YYYY-MM-DD" ></el-date-picker><span style="padding: 0 15px;">-</span>
					<el-date-picker v-model="endDate" type="date" value-format="YYYY-MM-DD" ></el-date-picker>
					</el-config-provider>
				</div>
		    </div>
		
		    <div class="ucFrom-row">
			<div class="ucForm-group">
				<label  class="ucForm-label">合同编号</label>
				<input name="cno" v-model="cno" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">合同名称</label>
				<input name="cname" v-model="cname" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">合同金额/元</label>
				<input name="amount" style="width:100px" v-model="amount" type="number" autocomplete="off" class="ucForm-control" placeholder="请输入" /><span style="padding: 0 15px;">-</span>
				<input name="amount" style="width:100px" v-model="amount2" type="number" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label class="ucForm-label">项目类别</label>
				<select v-model="xmlb" class="ucForm-control">
					<option  value="">请选择</option>
					<option v-for="option in xmlbs" v-bind:key = "option.id" :value="option.id">
						{{option.name}}
					</option>
				</select>
			</div>
		
			<div class="ucForm-group">
				<label class="ucForm-label">专业</label>
				<select v-model="zy" class="ucForm-control">
					<option  value="">请选择</option>
					<option v-for="option in zys" v-bind:key = "option.id" :value="option.id">
						{{option.name}}
					</option>
				</select>
			</div>
			<div class="ucForm-group">
				<label  class="ucForm-label">委托单位</label>
				<input name="gysmc" v-model="gysmc" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label class="ucForm-label">项目负责人</label>
				<input name="cname" v-model="xmfzr" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">工程投资</label>
				<input name="gctz" v-model="gctz" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">项目来源</label>
				<input name="xmly" v-model="xmly" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">主要内容</label>
				<input name="mark" v-model="mark" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
			
			<div class="ucForm-group">
				<label class="ucForm-label">框架协议</label>
				<select v-model="isframe" class="ucForm-control" >
					<option  value="">请选择</option>
					<option v-for="isframe in isframes" v-bind:key="isframe.value" :value="isframe.value">
						{{isframe.isframe}}
					</option>
				</select>
			</div>
			<div class="ucForm-group">
				<label class="ucForm-label">是否获奖</label>
				<select v-model="isaward" class="ucForm-control">
					<option  value="">请选择</option>
					<option v-for="isaward in isawards" v-bind:key="isaward.value" :value="isaward.value">
						{{isaward.isaward}}
					</option>
				</select>
			</div>
			<div class="ucForm-group">
				<label class="ucForm-label">合同状态</label>
				<select v-model="stat" class="ucForm-control">
					<option  value="">请选择</option>
					<option v-for="stat in stats" v-bind:key="stat.value" :value="stat.value">
						{{stat.stat}}
					</option>
				</select>
			</div>
			<div class="ucForm-group">
				<label  class="ucForm-label">费率</label>
				<select v-model="fl" class="ucForm-control">
					<option  value="">请选择</option>
					<option v-for="fl in fls" v-bind:key="fl.value" :value="fl.value">
						{{fl.fl}}
					</option>
				</select>
			</div>
		    </div>
			<div class="ucFrom-row">
			<div class="ucForm-group" style="width:100%">
				<label class="ucForm-label">签订部门</label>
				<el-select v-model="deptId" multiple class="elSelectFull">
					<el-option v-for="option in departments" :key="option.id" :label="option.name" :value="option.id">
						{{option.name}}
					</el-option>
				</el-select>
			</div>
			</div>
			<div class="clear"></div>
		</div>
	    <div class="ctms_srhBtn">
			<button class="ucBtn ucBtn-search ucBtn-green"  @click="handleClickQuery()"><i class="iImg"></i>查询</button>
			<button class="ucBtn ucBtn-import ucBtn-green"  @click="handleClickExport()"><i class="iImg"></i>导出</button>
			<!-- <button class="ucBtn ucBtn-export ucBtn-green" @click="handleClickImport()"><i class="iImg"></i>导入合同列表</button> -->
		</div>
	</div>
	<div class="ucPanel bgGrayBox" style="background-color: #fff;">
		<div class="ctms_dataSta">
			<ul>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img1.png"/></i>
						<dt>合同数量 (服务) / 项</dt>
						<dd>{{serviceCount}}</dd>
					</dl>
				</li>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img2.png"/></i>
						<dt>合同数量 (采购) / 项</dt>
						<dd>{{purchaseCount}}</dd>
					</dl>
				</li>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img3.png"/></i>
						<dt>合同金额 (服务) / 万元</dt>
						<dd>{{serviceContractAmount}}</dd>
					</dl>
				</li>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img4.png"/></i>
						<dt>合同金额 (采购) / 万元</dt>
						<dd>{{purchaseContractAmount}}</dd>
					</dl>
				</li>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img5.png"/></i>
						<dt>进款额 / 万元</dt>
						<dd>{{revenueAmount}}</dd>
					</dl>
				</li>
			</ul>
			<div class="clear"></div>
		</div>
	</div>
	
	<div class="ucPanel ucPanel-table">
		
			
			<el-table :data="dataList" highlight-current-row border style="width: 100%">
				<el-table-column type="selection" width="55px" align="center"/>
				<el-table-column label="序号" width="78px;">
					<template v-slot="scope">
						{{ scope.$index+1 }}
					</template>
				</el-table-column>
				<el-table-column label="部门" >
					<template v-slot="scope">
						<a @click="goto(scope.row)" style="cursor: pointer;">{{ scope.row.department}}</a>
					</template>
				</el-table-column>
				<el-table-column label="合同数量（服务）">
					<template v-slot="scope">
						{{ scope.row.serviceCount }}
					</template>
				</el-table-column>
				<el-table-column label="合同数量（采购）">
					<template v-slot="scope">
						{{ scope.row.purchaseCount }}
					</template>
				</el-table-column>
				<el-table-column label="合同金额（服务）/ 万元  ">
					<template v-slot="scope">
						{{ scope.row.serviceContractAmount }}
					</template>
				</el-table-column>
				<el-table-column label="合同金额（采购）/ 万元" >
					<template v-slot="scope">
						{{ scope.row.purchaseContractAmount }}
					</template>
				</el-table-column>
				<el-table-column label="进款额 / 万元">
					<template v-slot="scope">
						{{ scope.row.revenueAmount }}
					</template>
				</el-table-column>
			</el-table>
			
			<ctms_contract_info @closecallinfo="closecallinfo"  v-if="centerDialogVisible"></ctms_contract_info>
	
	</div>
	
</template>
<script>
	import ctms_contract_info from './ctms_contract_info.vue';
	import ctms_contract_list from './ctms_contract_list.vue';
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	export default {
		name: 'ZhProvider',
		components:{
			[ElConfigProvider.name]: ElConfigProvider,
			// ctms_contract_list
			
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		props: {
			msg: String
		},
		data: function() {
			return {
				id:"",
				centerDialogVisible: false,
				dataList: [{gid:"",department:'xxxxxxxxx',serviceCount:'xxxxxxxxx',purchaseCount:'xxxxxxxxx',serviceContractAmount:'xxxxxxxxx',purchaseContractAmount:'xxxxxxxxx',revenueAmount:'xxxxxxxxx'}],
				// dataList: [],
				infoMod: "",
				editId: null,
				beginDate:'',
				endDate:'',
				serviceCount:0,
				purchaseCount:0,
				serviceContractAmount:0,
				purchaseContractAmount:0,
				revenueAmount:0,
				cno:null,
				gid:null,
				cname:null,
				amount:null,
				amount2:null,
				xmlb:null,
				department:null,
				gysmc:null,
				xmfzr:null,
				gctz:null,
				xmly:null,
				zynr:null,
				fl:null,
				deptId:null,
				ctypes: [
					{ ctype: '服务合同', value: '1' },
					{ ctype: '采购合同', value: '2' }
				],
				major:null,
				zys:[],
				xmlbs:[],
				departments:[],
				isframes:[
					{isframe:"是",value:'1'},
					{isframe:"否",value:'2'}
				],
				isawards:[
					{isaward:"是",value:'1'},
					{isaward:"否",value:'2'}
				],
				stats:[
					{stat: '正常', value: '1' },
					{stat: '作废', value: '2' }
				],
				fls:[
					{fl: '是', value: '1' },
					{fl: '否', value: '2' }
				],
				shouldshow:false,
				putawayname:'收起',
				zy:null
				
				
				
				// data:{serviceCount,purchaseCount,serviceContractAmount,purchaseContractAmount},
				
			}
		},
		created() {
			// this.searchTotalContractList();
			// this.searchTotalContract();
			this.setDate();
			this.searchMajors();
			this.searchDepartment();
			this.getType();
			this.handleClickQuery();
		},
		methods: {
			handleClickEdit: function(data){
				this.infoMod = "edit";
				this.editId = data.id;
				this.centerDialogVisible = true;
			},
			handleClickDelete: function(data){
				this.$confirm('您确定要删除区域“'+data.name+'”吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'}).then(()=>{
						this.deleteData(data.id);
					});
			},
			handleClickQuery:function(data){
				this.dataList = [];
				var req = {};
				req.cmd = "searchTotalContractList";
				req.cno = this.cno;
				if(this.deptId==''||this.deptId==null){
					req.deptId = '';
				}else{
					req.deptId = this.deptId.join(',');
				}
				req.gid = this.gid;
				req.beginDate = this.beginDate;
				req.endDate = this.endDate;
				req.ctype = this.ctype;
				req.cname = this.cname;
				req.xmlb = this.xmlb;
				req.amount = this.amount;
				req.amount2 = this.amount2;
				req.zy = this.zy;
				req.xmfzr = this.xmfzr;
				req.gctz = this.gctz;
				req.xmly = this.xmly;
				req.mark = this.mark;
				req.isframe = this.isframe;
				req.isaward = this.isaward;
				req.stat = this.stat;
				req.fl = this.fl;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res);
					this.dataList=res.datas.result.totalContracts;
					this.department = res.datas.result.totalContracts.department;
					this.serviceCount = res.datas.total.serviceCount;
					this.purchaseCount = res.datas.total.purchaseCount;
					this.purchaseContractAmount = res.datas.total.purchaseContractAmount;
					this.serviceContractAmount = res.datas.total.serviceContractAmount;
					this.revenueAmount=res.datas.total.revenueAmount;
				}, null, this);	
			},
			handleClickAdd: function(){
				
			},
			fetchData: function(){
				this.dataList = [];
				var req = {};
				req.cmd = "mng_conf_area_search";
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.dataList = res.areas;
				}, null, this);	
			},
			deleteData: function(id){
				this.dataList = [];
				var req = {};
				req.cmd = "mng_conf_area_del";
				req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.fetchData();
				}, null, this);	
			},
			closecallinfo: function() {
				this.centerDialogVisible = false;
				this.fetchData();
			},
			goto:function(data) {
				console.log(data)
				this.$router.push({name:'ctms_c_list',params:data});
			},
			searchTotalContractList: function(){
				this.dataList = [];
				var req = {};
				req.cmd = "searchTotalContractList";
				console.log(this.deptId);
				return;
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res);
					this.dataList=res.datas.result.totalContracts;
					this.department = res.datas.result.totalContracts.department;
				}, null, this);	
			},
			searchTotalContract: function(){
				this.dataList = [];
				var req = {};
				req.cmd = "searchTotalContract";
				req.cno = this.cno;
				if(this.deptId==''||this.deptId==null){
					req.deptId = '';
				}else{
					req.deptId = this.deptId.join(',');
				}
				req.gid = this.gid;
				req.beginDate = this.beginDate;
				req.endDate = this.endDate;
				req.ctype = this.ctype;
				req.cname = this.cname;
				req.xmlb = this.xmlb;
				req.amount = this.amount;
				req.amount2 = this.amount2;
				req.zy = this.zy;
				req.xmfzr = this.xmfzr;
				req.gctz = this.gctz;
				req.xmly = this.xmly;
				req.mark = this.mark;
				req.isframe = this.isframe;
				req.isaward = this.isaward;
				req.stat = this.stat;
				req.fl = this.fl;
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res);
					this.serviceCount = res.datas.result.serviceCount;
					this.purchaseCount = res.datas.result.purchaseCount;
					this.purchaseContractAmount = res.datas.result.purchaseContractAmount;
					this.serviceContractAmount = res.datas.result.serviceContractAmount;
					this.gid = res.datas.result.gid;
				}, null, this);	
			},
			searchMajors: function(){
				this.zys = [];
				var req = {};
				req.cmd = "searchMajors";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result);
					this.zys = res.datas.result.majors;
				}, null, this);	
			},
			searchDepartment:function(){
				this.departments = [];
				var req = {};
				req.cmd = "searchDepartment";
				req.type=1;
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.departments);
					this.departments = res.datas.result.departments;
				}, null, this);	
			},
			getType:function(){
				this.xmlbs = [];
				var req = {};
				req.cmd = "getType";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.majors);
					this.xmlbs = res.datas.result.majors;
					console.log(this.xmlbs);
				}, null, this);	
			},
			handleClickExport:function(){
				var req = {};
				req.cmd = "exportContractTotalExcel";
				req.cno = this.cno;
				if(this.deptId==''||this.deptId==null){
					req.deptId = '';
				}else{
					req.deptId = this.deptId.join(',');
				}
				req.beginDate = this.beginDate;
				req.endDate = this.endDate;
				req.cname = this.cname;
				req.xmlb = this.xmlb;
				req.amount = this.amount;
				req.amount2 = this.amount2;
				req.zy = this.zy;
				req.xmfzr = this.xmfzr;
				req.gctz = this.gctz;
				req.xmly = this.xmly;
				req.mark = this.mark;
				req.isframe = this.isframe;
				req.isaward = this.isaward;
				req.stat = this.stat;
				req.fl = this.fl;
				this.$sknet.download(this.$skconf("iot.surl"), req)
			},
			handleSizeChange: function (size) {
				this.pagesize = size;
				console.log(this.pagesize)  //每页下拉显示数据
			},
			handleCurrentChange: function(currentPage){
				this.currentPage = currentPage;
				this.fetchData();
				console.log(this.currentPage)  //点击第几页
			},
			handleClick: function(data){
				console.log(data)
				this.type=data.props.name;
				this.ctype=data.props.name;
				this.currentPage=1;
				this.pagesize=10;
				this.fetchData();
			},
			setDate:function() {
				const y = new Date().getFullYear();
				const f = y + '-01-01';
				const l = y + '-12-31';
				this.beginDate = f;
				this.endDate=l;
			},
			putaway:function() {
				if(this.putawayname=='收起'){
					this.shouldshow=true;
					this.putawayname='打开';
				}else{
					this.shouldshow=false;
					this.putawayname='收起';
				}
			}
			// handleClickImport:function(){
			// 	var req = {};
			// 	req.cmd = "importContractExcel";
			// 	this.$sknet.download(this.$skconf("iot.surl"), req)
			// }
		},
		
		watch: {
		},
		components: {
			ctms_contract_info
		},
		mounted: function() {}
	}
</script>